import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Flex,
  Button,
  Text,
  Input,
  Stack,
  Box,
  SimpleGrid,
  Divider,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react'
import { FiBookmark, FiCalendar, FiRefreshCcw } from 'react-icons/fi'
import { AiFillCheckCircle } from 'react-icons/ai'

import Layout from '../components/layout'
import WhatsappButton from '../components/whatsappButton'

import {
  Article,
  FeatureItem,
  FeatureList,
  Hero,
  HeroBody,
  HeroCta,
  HeroSubtitle,
  HeroTitle,
  Image,
  P,
  Section,
  FloatingBanner,
  RowContent,
  ColInfo,
  ColImage,
  Testimonials,
  TestimonialItem,
  H3,
} from '../ui'

import logoCjf from '../images/cjf.png'
import logoDof from '../images/dof.png'
import landing1 from '../images/mockup.jpg'

import feature1 from '../images/index/agenda.jpg'
import feature2 from '../images/index/mutiplataforma3.jpg'
import feature3 from '../images/index/storyprocess.jpg'

import PopupContent from '../components/popupContent'
import Faqs from '../components/faqs'

function IndexPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout title="Calculadora Legal">
      <Hero
        height="fit-content"
        paddingTop={{ base: 20 }}
        paddingBottom={{ base: 8 }}
        backgroundColor="white"
      >
        <HeroBody
          spacing={4}
          maxWidth={{ md: '800px' }}
          textAlign="center"
          marginX="auto"
          justifyContent="center"
        >
          <HeroTitle
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
            fontWeight="bold"
            color="inherit"
          >
            Calcula tus Plazos Jurídicos con Fundamento Legal
          </HeroTitle>
          <HeroSubtitle color="inherit" lineHeight="tall">
            Olvídate de errores calculando tus plazos jurídicos. Calcula tus plazos en tres simples
            pasos y crea notificaciones para recordarlos fácilmente.
          </HeroSubtitle>
          <Divider />
          <WhatsappButton />
          <Divider />
        </HeroBody>
      </Hero>

      <Article>
        {/* <Section width="80%" paddingTop={{ base: '0', lg: 'inherit' }}>
          <P width="100%" textAlign="center">
            Con información de:
          </P>
          <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
            <Box as="img" src={logoCjf} objectFit="contain" />
            <Box as="img" src={logoDof} objectFit="contain" />
          </Flex>
        </Section> */}

        <Section title="¡Conoce la primera Calculadora Legal en México!" titleAlign="center">
          <Flex
            as={Stack}
            spacing={{ base: 10, md: 0 }}
            flexDir={{ base: 'column', lg: 'row' }}
            width="100%"
            marginX="auto"
            justifyContent="center"
            maxWidth="1240px"
          >
            <Box
              as="img"
              src={landing1}
              objectFit="contain"
              width={{ base: '100%', lg: '60%' }}
              marginX="auto"
            />
            <Stack spacing={6} padding={{ base: 0, lg: 8 }} width={{ base: '100%', lg: '40%' }}>
              <FeatureItem
                icon={<FiRefreshCcw color="#fca311" size="3.5rem" />}
                alignItems="center"
                title="Normativa aplicable actualizada"
                textAlign={{ base: 'justify', lg: 'center' }}
                desc="La aplicación se actualizará diariamente con base a los acuerdos publicados por el CJF o nuevas reformas para el DOF."
              />
              <FeatureItem
                icon={<FiCalendar color="#fca311" size="3.5rem" />}
                alignItems="center"
                title="Configura tu calendario y notificaciones"
                textAlign={{ base: 'justify', lg: 'center' }}
                desc="Podrás organizar y personalizar todas las notificaciones de tu calendario de plazos, para que evites la preclusión de tu derecho."
              />
              <FeatureItem
                icon={<FiBookmark color="#fca311" size="3.5rem" />}
                alignItems="center"
                title="Fundamento legal en todos tus cálculos"
                textAlign={{ base: 'justify', lg: 'center' }}
                desc="Después de realizar tu cálculo de plazos, podrás consultar todo el fundamento legal de los días inhábiles, artículos y toda la información relevante."
              />
            </Stack>
          </Flex>
        </Section>

        {/* <Section fullWidth backgroundColor="gray.100">
          <H3 textAlign="center" fontSize={{ base: '2xl', md: '3xl' }} paddingX={2} paddingY={6}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non egestas erat.
          </H3>
        </Section> */}

        <Section
          spacing={10}
          title="¿Cómo funciona la aplicación Calculadora Legal?"
          titleAlign="center"
        >
          <RowContent>
            <ColImage
              paddingBottom="0"
              image={<Box as="img" src={feature3} objectFit="contain" maxHeight="500px" />}
            />
            <ColInfo
              title="Calcula tus plazos en tres simples pasos"
              subtitle="i) Basta con que llenes la información de tu plazo, ii) visualiza tus términos calculados en el calendario y iii) configura tus notificaciones para que jamás tengas que preocuparte por tus fechas límite."
              textAlign={{ base: 'justify', lg: 'left' }}
            />
          </RowContent>

          <RowContent reversedInMobile>
            <ColInfo
              title="Tus cálculos se almacenan y se actualizan diariamente"
              subtitle="En caso de que cambie la normativa o las reformas, tus plazos se actualizarán automáticamente, para que no tengas que volverlos a calcular."
              textAlign={{ base: 'justify', lg: 'left' }}
            />
            <ColImage
              paddingBottom="0"
              image={<Box as="img" src={feature1} objectFit="contain" maxHeight="500px" />}
            />
          </RowContent>

          <RowContent>
            <ColImage
              paddingBottom="0"
              image={<Box as="img" src={feature2} objectFit="contain" maxHeight="500px" />}
            />
            <ColInfo
              textAlign={{ base: 'center', lg: 'left' }}
              title="Calcula tus plazos desde cualquier dispositivo"
              subtitle="Mantén la información importante a tu alcance. Calcula y obtén tus plazos desde tu teléfono o computadora, sin importar donde te encuentres."
              textAlign={{ base: 'justify', lg: 'left' }}
            />
          </RowContent>
        </Section>

        <PopupContent disableCloseButton />

        {/*<Section spacing={8}>
          <FeatureList>
            <FeatureItem
              textAlign="center"i)
              alignItems="center"
              icon={<FcRefresh size="4rem" />}
              title="Normatividad aplicable actualizada"
              desc="Somos la única calculadora que se actualiza diariamente en base a los acuerdos publicados por el CFJ o las reformas en el DOF. Evitando errores y garantizando que todos nuestros cálculos son correctos."
            />
            <FeatureItem
              textAlign="center"
              alignItems="center"
              icon={<FcOvertime size="4rem" />}
              title="Configura tu calendario y notificaciones"
              desc="Organiza tus plazos y recibe notificaciones de todos tus términos para evitar la preclusión de tu derecho."
            />
            <FeatureItem
              textAlign="center"
              alignItems="center"
              icon={<FcTimeline size="4rem" />}
              title="Todos tus cálculos con fundamento legal"
              desc="Después de realizar tu cálculo de plazos podrás consultar todo el fundamento legal de los días inhábiles, artículos entre otra información relevante."
            />
          </FeatureList>
          <FeatureList>
            <FeatureItem
              textAlign="center"
              alignItems="center"
              icon={<FcDiploma1 size="4rem" />}
              title="Herramienta de cálculo confiable"
              desc="Siéntete seguro que nuestra tecnología te dará la certeza de que tu término fue calculado correctamente."
            />
            <FeatureItem
              textAlign="center"
              alignItems="center"
              icon={<FcFlashOn size="4rem" />}
              title="Cómputo de plazos en segundos"
              desc="Calcula tus términos legales en un par de clics en menos de 30 segundos. Fácil y rápido con fundamento legal para los días inhábiles."
            />
            <FeatureItem
              textAlign="center"
              alignItems="center"
              icon={<FcMultipleDevices size="4rem" />}
              title="Haz tus cálculos desde cualquier dispositivo"
              desc="Obtén tus plazos sin importar en donde te encuentres, desde tu teléfono o computadora, y mantén la información importante a tu alcance."
            />
          </FeatureList>
  </Section>*/}

        <Faqs
          title="Preguntas frecuentes"
          list={[
            {
              question: '¿Qué es CalculadoraLegal?',
              answer:
                'Es un servicio de software que automatiza el cálculo de plazos y términos jurídicos establecidos en la ley mexicana.',
            },
            {
              question: '¿Cuánto cuesta CalculadoraLegal?',
              answer:
                'Actualmente el software está en modo desarrollo. Por lo tanto el precio mensual no está determinado.',
            },
            {
              question: '¿Qué materias jurídicas estarán disponibles en CalculadoraLegal?',
              answer:
                'La primera versión incluirá solamente materias de jurisdicción federal. Por ejemplo, juicio contencioso administrativo federal, juicio de amparo, procedimiento penal acusatorio y juicio mercantil.',
            },
            {
              question: '¿Qué características tendrá CalculadoraLegal?',
              answer:
                'La primera versión tendrá la opción de guardar pendientes, seleccionar fecha para ser notificado cuando esté a punto de acabar tu plazo, fundamento legal aplicable.',
            },
            {
              question: '¿Cómo funciona CalculadoraLegal?',
              answer:
                'Ingresas los datos del acto procesal que deseas calcular y la fecha en que fue notificado, visualiza tu calendario y consulta el fundamento legal.',
            },
            {
              question: '¿Qué tan confiable es CalculadoraLegal?',
              answer:
                'Todos los cálculos son orientadores y se expresa un fundamento aplicado al caso en concreto que puede ser revisado libremente por el usuario.',
            },
            {
              question:
                '¿Qué hace CalculadoraLegal cuando sale un nuevo acuerdo que afecta un plazo ya calculado?',
              answer:
                'Cuando un acuerdo nuevo sale, la base de datos se actualiza automáticamente, así como todos tus plazos y notificaciones que hayan sido afectados.',
            },
          ]}
        />

        <FloatingBanner>
          <WhatsappButton />
        </FloatingBanner>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "index/hero2.png" }) {
      ...bannerSectionImage
    }
  }
`

export default IndexPage
