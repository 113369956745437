import React from 'react'
import { v4 } from 'uuid'

import { Accordion, AccordionItem, Section } from '../ui'

function Faqs({ title, list }) {
  console.log(list)
  return (
    <Section title={title}>
      <Accordion>
        {list.map((ele) => {
          return <AccordionItem key={v4()} title={ele.question} desc={ele.answer} />
        })}
      </Accordion>
    </Section>
  )
}

export default Faqs
